body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Zain', sans-serif;
}

.submit-form-container {
  background-color: #FFECB3; /* Light orange background */
  padding: 50px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Zain', sans-serif;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  padding: 10px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  width: 300px;
}

.button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #FFA726;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #FB8C00;
}
