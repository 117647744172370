/* src/LandingPage.css */
.landing-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: linear-gradient(135deg, #f3ec78, #af4261);
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.header {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.header h1 {
  margin: 0;
  font-size: 3rem;
  font-family: 'Playwrite CU', sans-serif;
}

.header p {
  margin: 0.5rem 0 0;
  font-size: 1.5rem;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.quote {
  max-width: 600px;
  margin: 1rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-style: italic;
}

.quote p {
  margin: 0;
  font-size: 1.5rem;
}

.quote footer {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: right;
}

.footer {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer p {
  margin: 0;
  font-size: 1rem;
}


.link-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #cc5500;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #FB8C00;
}
