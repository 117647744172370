body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.display-text-container {
  background-color: black;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
